import React, {Component} from 'react';
import {withLocalize} from 'react-localize-redux';
import {Fab, Icon} from '@material-ui/core'
import Header from "../header/Header";
import connect from "react-redux/es/connect/connect";
import StepLabel from "@material-ui/core/es/StepLabel/StepLabel";
import Step from "@material-ui/core/es/Step/Step";
import Stepper from "@material-ui/core/es/Stepper/Stepper";
import ReserveForm from "./ReserveForm";
import ReserveList from "./ReserveList";
import ReserveInput from "./ReserveInput";
import ReserveComplete from "./ReserveComplete";
import NotFound from "../notfound/NotFound";
import StepButton from "@material-ui/core/StepButton/StepButton";
import MapIcon from '@material-ui/icons/Map';

const mapStateToProps = (state, props) => {
    return {
        settings: state.settings,
        regions: state.regions
    }
}

const mapDispatchToProps = dispatch => {
    return {}
};


class Reserve extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settings: props.settings,
            activeStep: 0,
        };
    }

    componentDidUpdate(prevProps, prevState) {
    }

    handleNext = (value) => {
        this.setState(state => ({
            activeStep: state.activeStep + 1,
        }));
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    saveSearchData = (value) => {
        this.setState({
            searchData: value
        })
    };

    saveSelectData = (value) => {
        this.setState({
            selectData: value
        })
    };

    saveCompleteData = (timeValue, count) => {
        this.setState({
            timeData: timeValue,
            countData: count
        })
    };

    getStepContent = (stepIndex) => {
        const {translate} = this.props;
        switch (stepIndex) {
            case 0:
                return <ReserveForm handleNext={this.handleNext} handleBack={this.handleBack}
                                    saveSearchData={this.saveSearchData} searchData={this.state.searchData}/>;
            case 1:
                return <ReserveList searchData={this.state.searchData} handleNext={this.handleNext}
                                    handleBack={this.handleBack} saveSelectData={this.saveSelectData}/>;
            case 2:
                return <ReserveInput selectData={this.state.selectData} searchData={this.state.searchData}
                                     saveCompleteData={this.saveCompleteData}
                                     handleNext={this.handleNext} handleBack={this.handleBack}/>
            case 3:
                return <ReserveComplete timeData={this.state.timeData} selectData={this.state.selectData}
                                        countData={this.state.countData}/>
            default:
                return <NotFound/>;
        }
    };

    render() {
        const styles = {
            root: {
                maxWidth: 400,
                flexGrow: 1,
            },
        };
        const {activeStep} = this.state;
        const {languages, activeLanguage, setActiveLanguage, translate, account, history} = this.props;
        return (
            <div className="App">
                <Header
                    title={translate("reserve")}
                    showMenuButton={true}
                    showLanguageChangeButton={false}
                />
                <div className={"Container with-stepper"}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        <Step key={1}>
                            <StepButton
                                onClick={() => {
                                    this.setState({
                                        activeStep: 0
                                    })
                                }}
                                icon={
                                    activeStep === 0 ?
                                        <Icon color={"primary"}>
                                            search
                                        </Icon>
                                        : 1
                                }>
                                {translate("reserve_step_1")}
                            </StepButton>
                        </Step>
                        <Step key={2}>
                            <StepButton
                                icon={
                                    activeStep > 1 ?
                                        2 :
                                        <Icon color={activeStep >= 1 ? "primary" : "disabled"}>list_alt</Icon>
                                }
                                onClick={() => {
                                    this.setState({
                                        activeStep: 1
                                    })
                                }}>
                                {translate("reserve_step_2")}
                            </StepButton>
                        </Step>

                        <Step key={3}>
                            <StepButton
                                onClick={() => {
                                    this.setState({
                                        activeStep: 2
                                    })
                                }}
                                icon={
                                    activeStep > 2 ?
                                        3 :
                                        <Icon
                                            color={activeStep >= 2 ? "primary" : "disabled"}>create</Icon>}
                                >
                                {translate("reserve_step_3")}
                            </StepButton>
                        </Step>
                        <Step key={4}>
                            <StepLabel icon={<Icon
                                color={activeStep >= 3 ? "primary" : "disabled"}>done_outline</Icon>}>{translate("reserve_step_4")}</StepLabel>
                        </Step>
                    </Stepper>

                    {/*
                    {activeStep === 1 ?
                        <MobileStepper
                            variant={"text"}
                            steps={4}
                            position="static"
                            activeStep={activeStep}

                            backButton={
                                <Button size="small" onClick={this.handleBack} disabled={activeStep === 0}>
                                    <KeyboardArrowLeft/>
                                    {translate("back")}
                                </Button>
                            }
                        />
                        :
                        undefined
                    }
                    */}
                    <div>
                        {this.getStepContent(activeStep)}
                    </div>
                    {/*
                    <div>
                        {this.state.activeStep === steps.length ? (
                            <div>
                                <Typography>All steps completed</Typography>
                                <Button onClick={this.handleReset}>Reset</Button>
                            </div>
                        ) : (
                            <div>
                                {this.getStepContent(activeStep)}
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={this.handleBack}
                                    >
                                        Back
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={this.handleNext}>
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                    */}
                </div>
            </div>
        )
    }
}


Reserve.propTypes = {};

Reserve.defaultProps = {};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(Reserve));