import React from 'react';
import {connect} from "react-redux";
import * as Helper from "utils/Helper"
import log from "utils/logger";

const mapStateToProps = (state, props) => {
    return {
        target: state.target
    }
};

class MapLoader extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: true
        };
    };

    componentDidMount() {
        const key = Helper.getUniversityCodeFromDomain();
        log.debug(key);
        if(this.props.target.payload === "client") {
           import(`./${key}/MapMobile`)
                .then((module) => {
                    return this.setState({
                        module: module.default,
                    });
                })
                .catch((e) => {
                    return console.log(e);
                });
        }else{
            //todo MobileかMobile用じゃないかで処理をしたいが一旦 mobileですべてまかなえる気がする
           import(`./${key}/MapMobile`)
                .then((module) => {
                    return this.setState({
                        module: module.default,
                    });
                })
                .catch((e) => {
                    return console.log(e);
                });
        }
    };

    render () {
        const {module: Component} = this.state;
        return (
            <div>
                {Component && <Component />}
            </div>
        );
    }
}

export default connect(
    mapStateToProps
)(MapLoader)
