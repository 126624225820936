import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from "react-router-dom";
import moment from 'moment';
import 'moment/locale/ja';
import * as Actions from "../../redux/actions";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {Typography} from "@material-ui/core";
import {withLocalize} from "react-localize-redux";
import ReserveInput from "../reserve/ReserveInput";
import AppBar from "@material-ui/core/es/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from '@material-ui/icons/Close';
import ReserveComplete from "../reserve/ReserveComplete";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";

moment.locale('ja');

const mapStateToProps = (state, props) => {
    return {
        login: state.login,
        openingConnection: state.openingConnection,
        openingConnectionTimePreset: state.openingConnectionTimePreset,
        ticketConnection: state.ticketConnection,
    }
};
const mapDispatchToProps = dispatch => {
    return {

    }
};

let errorRef = undefined;

const styles = {
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
};


class MapScheduleDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isOpen: false,
            searchParams: props.searchParams,
            completeData: undefined,
            announcementDialogIsOpen: false,

            isCompleted: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.visible !== this.props.visible){
            this.setState({
                searchParams: nextProps.searchParams,
                selectData: nextProps.selectData,
                searchData: nextProps.searchData,
                isOpen: nextProps.visible,


                //選択用DateMoment
                startDateMoment: moment(nextProps.searchData.date_start).startOf("day"),
                endDateMoment: moment(nextProps.searchData.date_end).endOf("day"),
            })
        }

        if (nextProps.ticketConnection !== this.props.ticketConnection) {
            this.setState({
                loading: nextProps.ticketConnection.meta.fetch
            });
        }

        if (nextProps.openingConnection !== this.props.openingConnection) {
            if (nextProps.openingConnection.meta.status === Actions.statusEnum.SUCCESS) {
                if (errorRef) {
                    errorRef.destroy();
                }
                this.handleCancel();
            }
        }

        if (nextProps.openingConnectionTimePreset !== this.props.openingConnectionTimePreset) {
            if (nextProps.openingConnectionTimePreset.meta.status === Actions.statusEnum.SUCCESS) {
                console.log(nextProps.openingConnectionTimePreset)
                this.setState({
                    timePresetData: nextProps.openingConnectionTimePreset.payload.result,
                },()=>{
                    if (this.state.timePresetData.item.item_announcement_en !== "" || this.state.timePresetData.item.item_announcement !== "") {
                        this.setState({
                            announcementDialogIsOpen: true
                        });
                    }
                });
            }
        }
    }



    handleClose = () =>{
        this.setState({
            isCompleted: false
        })
        this.props.onCancel();
    };



    saveCompleteData = (timeValue, count) => {
        this.setState({
            timeData: timeValue,
            countData: count
        })
    };


    render() {
        const {translate, account, activeLanguage} = this.props;
        return (
            <Dialog
                fullWidth={true}
                fullScreen={true}
                scroll={'paper'}
                open={this.state.isOpen}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
            >

                <AppBar className={"relative"}>
                    <Toolbar>
                        <div id="header-logo">
                        </div>
                        <div id={"header-title"}>
                            <Typography variant="h6" color="inherit">
                                {this.props.title}
                            </Typography>
                        </div>
                        <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    {!this.state.isCompleted ?
                        <ReserveInput
                            isMap={true}
                            selectData={this.state.selectData}
                            searchData={this.state.searchData}
                            saveCompleteData={this.saveCompleteData}
                            handleNext={() => {
                                this.setState({
                                    isCompleted: true
                                })
                            }}
                            handleBack={this.handleClose}/>
                        :
                        <ReserveComplete timeData={this.state.timeData} selectData={this.state.selectData}
                                         countData={this.state.countData}/>
                    }
                </DialogContent>

                <Dialog
                    fullWidth={true}
                    open={this.state.announcementDialogIsOpen}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">{translate("caution")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.state.selectData ?
                                this.props.activeLanguage.code === "jp" ?
                                    this.state.timePresetData.item.item_announcement
                                    :
                                    this.state.timePresetData.item.item_announcement_en
                                :
                                null
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            this.setState({
                                announcementDialogIsOpen: false
                            })
                        }} color="primary">
                            {translate("alert_agree")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Dialog>
        );
    }
}


MapScheduleDialog.defaultProps = {
    title: "",
    loading: false,

};



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLocalize(MapScheduleDialog)));
