import React from "react"
import {ReactSvgPanZoomLoader} from "../ReactSvgPanZoomLoader";
import {ReactSVGPanZoom, TOOL_AUTO, UncontrolledReactSVGPanZoom} from "react-svg-pan-zoom";
import {withRouter} from "react-router";
import * as Actions from 'redux/actions'
import {connect} from "react-redux";
import * as GlobalConfig from "../../../constants/GlobalConfig";
import moment from 'moment';
import {openingConnectionTimePreset} from "../../../redux/reducers";
import MapShceduleDialog from "../MapShceduleDialog";
import Header from "../../header/Header";
import LoadingOverlay from "react-loading-overlay";
import log from 'utils/logger';

const mapStateToProps = (state, props) => {
    return {
        mapStatus: state.mapStatus,
        mapLabels: state.mapLabels,
        timePresetSetsConnection: state.timePresetSetsConnection,
        mapStatusConnection: state.mapStatusConnection,
        openingConnectionTimePreset: state.openingConnectionTimePreset,
        regions: state.regions
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getStatus: () => {
            dispatch(Actions.http.connection.mapStatus.get())
        },
        getTimePresets: (value) => {
            dispatch(Actions.http.connection.openings.timePreset(value));
        },
    }
};

class MapPanZoomWrapper extends React.Component {
    constructor(props) {
        super(props)


        const region = new URLSearchParams(props.location.search).get('region');
        let floorPath;
        switch (region) {
            case 1:
            case "1":
                floorPath = process.env.PUBLIC_URL + '/svg/daikoudai/oit_building5_5f.svg';
                break;
            case 2:
            case "2":
                floorPath = process.env.PUBLIC_URL + '/svg/daikoudai/oit_building5_7fa.svg';
                break;
            case 3:
            case "3":
                floorPath = process.env.PUBLIC_URL + '/svg/daikoudai/oit_building5_7fb.svg';
                break;

        }

        this.Viewer = null;
        this.state = {
            mapStatus: props.mapStatus.payload,
            mapLabels: props.mapLabels.payload,
            isReady: false,
            tool: TOOL_AUTO,
            value: {},
            currentCampus: 1,
            region_id: region,
            floorPath
        }
    }


    componentWillMount() {
        this.props.getStatus();
    }

    componentDidMount() {
        this.Viewer.fitToViewer();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.mapStatusConnection !== nextProps.mapStatusConnection) {
            if (nextProps.mapStatusConnection.meta.status === Actions.statusEnum.SUCCESS) {
                this.setState({
                    loading: false
                })
            } else if (nextProps.mapStatusConnection.meta.status === Actions.statusEnum.ERROR) {
                this.setState({
                    showError: true,
                    loading: false
                })
            }
        }

        if (this.props.mapStatus !== nextProps.mapStatus) {
            this.setState({
                mapStatus: nextProps.mapStatus.payload
            }, () => {
                this.handleSvgLoaded();
            })
        }
        if (nextProps.openingConnectionTimePreset !== this.props.openingConnectionTimePreset) {
            if (nextProps.openingConnectionTimePreset.meta.status === Actions.statusEnum.SUCCESS) {
                console.log(nextProps.openingConnectionTimePreset)
                this.setState({
                    timePresetData: nextProps.openingConnectionTimePreset.payload.result,
                    selectData: nextProps.openingConnectionTimePreset.payload.result,
                    visible: true
                });
            }
            this.setState({
                loading: nextProps.openingConnectionTimePreset.meta.fetch
            })
        }
    }


    saveSearchDataValue = () => {
        let selectAreas; //エリア一覧
        let initialArea; //初期化エリア
        this.props.regions.payload.forEach((value, index) => {
            if (value.region_id === this.state.region_id) {
                selectAreas = value.areas;
            }
        });
        initialArea = selectAreas[0].area_id;
        //FIXME エリア設定に関して色々するひつようがあるのでは？

        const searchData = {
            item_type: "all",
            region_id: this.state.region_id,
            area_id: initialArea,
            date_start: moment().format("YYYY-MM-DD"),
            date_end: moment().format("YYYY-MM-DD"),
        }
        return searchData;
    };


    handleClick = (e, currentValue) => {
        //二重ロードさせない
        if (!this.state.loading) {
            const searchData = this.saveSearchDataValue();
            this.setState({
                loading: true,
                searchData
            });
            this.props.getTimePresets({
                type: "map",
                item_id: currentValue,
                date_start: moment().format("YYYY-MM-DD"),
                date_end: moment().format("YYYY-MM-DD"),
                opening_requiring_count: 1
            })
        }

    };

    handleSvgLoaded = () => {
        let mapStatusObject = this.state.mapStatus;
        for (const currentValue in this.state.mapStatus) {
            let generateId = "reserve_" + currentValue;
            if (document.getElementById(generateId)) {
                let list = document.getElementById(generateId);
                list.removeEventListener(GlobalConfig.optimizedEvent, (e) => {
                    this.handleClick(e, currentValue)
                }, false);
                list.addEventListener(GlobalConfig.optimizedEvent, (e) => {
                    this.handleClick(e, currentValue)
                }, false);
                let status_class;
                switch (mapStatusObject[currentValue]) {
                    case 1:
                        status_class = "status-available";
                        break;
                    case 2:
                        status_class = "status-commingsoon";
                        break;
                    case 3:
                        status_class = "status-full";
                        break;
                    case 4:
                        status_class = "status-unavailable";
                        break;
                    default:
                        status_class = "status-available";
                        break;
                }

                const newClassName = `${list.getAttribute("class")} ${status_class}`;
                list.setAttribute('class', newClassName)
            }
        }
    };


    changeValue = (nextValue) => {
        this.setState({
            value: nextValue
        })
    };

    changeTool = (nextTool) => {
        this.setState({
            tool: nextTool
        })
    };

    render() {
        const {props} = this;
        return (
            <>
                {/*
                <MapEffectComponent isReady={this.state.isReady} initializeMap={this.initializeMap}/>
                */}
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    style={{
                        wrapper: {
                            overflow: this.props.active ? 'hidden' : 'scroll'
                        }
                    }}
                >
                    <ReactSvgPanZoomLoader
                        src={this.state.floorPath}
                        onSVGReady={this.handleSvgLoaded}
                        render={(content) => (
                            <ReactSVGPanZoom
                                background={"#ffffff"}
                                ref={Viewer => this.Viewer = Viewer}
                                width={props.width}
                                height={props.height}
                                onChangeTool={this.changeTool}
                                tool={this.state.tool}
                                value={this.state.value}
                                toolbarProps={{position: "none"}}
                                miniatureProps={{position: "none"}}
                                scaleFactor={1.2}
                                scaleFactorOnWheel={1.2}
                                scaleFactorMin={1.0}
                                preventPanOutside={true}
                                detectAutoPan={false}
                                disableDoubleClickZoomWithToolAuto={true}
                                onChangeValue={this.changeValue}>
                                <svg width={props.width} height={props.height}>
                                    {content}
                                </svg>
                            </ReactSVGPanZoom>
                        )}/>
                </LoadingOverlay>

                <MapShceduleDialog
                    searchParams={{opening_requiring_count: 1}}
                    selectData={this.state.selectData}
                    searchData={this.state.searchData}
                    visible={this.state.visible}
                    loading={this.state.loading}
                    onCancel={() => {
                        this.setState({
                            visible: false
                        })
                    }}
                    onOk={() => {
                        this.setState({
                            visible: true
                        })
                    }}
                />
            </>
        )
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(MapPanZoomWrapper))
