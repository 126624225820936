import {Fab} from "@material-ui/core";
import PinDrop from "@material-ui/icons/PinDrop";
import Dialog from "@material-ui/core/Dialog/Dialog";
import AppBar from "@material-ui/core/es/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {ReactSVG} from "react-svg";
import React, {useEffect, useRef, useState} from "react";
import {ReactSvgPanZoomLoader} from "../ReactSvgPanZoomLoader";
import {INITIAL_VALUE, ReactSVGPanZoom, TOOL_AUTO} from "react-svg-pan-zoom";
import {AutoSizer} from "react-virtualized";
import MapPanZoomWrapper from "./MapPanZoomWrapper";

function TooltipMap(props) {
    const [mapOpen, setMapOpen] = useState(false);
    const [floorPath, setFloorPath] = useState(null);
    const [value, setValue] = useState(INITIAL_VALUE);
    const [tool, setTool] = useState(TOOL_AUTO);
    const Viewer = useRef(null);


    useEffect(() => {
        switch (props.regionId) {
            case 1:
            case "1":
            case 2:
            case "2":
            case 3:
            case "3":
            case 4:
            case "4":
            case 5:
            case "5":
                setFloorPath(process.env.PUBLIC_URL + '/svg/daikoudai/oit_building5_5f_no_status.svg');
                break;
            case 6:
            case "6":
            case 8:
            case "8":
                setFloorPath(process.env.PUBLIC_URL + '/svg/daikoudai/oit_building5_7fa_no_status.svg');
                break;
            case 7:
            case "7":
                setFloorPath(process.env.PUBLIC_URL + '/svg/daikoudai/oit_building5_7fb_no_status.svg');
                break;
            case 9:
            case "9":
                setFloorPath(null);
                break;

        }
    }, [props])

    const changeValue = (nextValue) => {
        setValue(nextValue);
    };

    const changeTool = (nextTool) => {
        setTool(nextTool)
    };


    return (
        floorPath &&
        <>
            <Fab id={'absolute-float-button'} color="primary" aria-label="add"
                 onClick={() => setMapOpen(true)} variant="extended">
                <PinDrop style={{marginRight: 5}}/>
                MAP
            </Fab>
            <Dialog
                fullScreen={true}
                open={mapOpen}
                onClose={() => {
                    setMapOpen(false)
                }}
                aria-labelledby="form-dialog-title"
            >
                <AppBar className={"relative"}>
                    <Toolbar>
                        <div id="header-logo">
                        </div>
                        <div id={"header-title"}>
                            <Typography variant="h6" color="inherit">

                            </Typography>
                        </div>
                        <IconButton color="inherit" onClick={() => {
                            setMapOpen(false)
                        }} aria-label="Close">
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <AutoSizer>
                    {({width, height}) =>
                        width === 0 || height === 0 ? null : (
                            <ReactSvgPanZoomLoader
                                src={floorPath}
                                onSVGReady={() => {
                                    //this.handleSvgLoaded
                                }}
                                render={(content) => (
                                    <ReactSVGPanZoom
                                        background={"#ffffff"}
                                        ref={Viewer}
                                        width={width}
                                        height={height - 64} // AppBar分殺す
                                        onChangeTool={changeTool}
                                        tool={tool}
                                        value={value}
                                        toolbarProps={{position: "none"}}
                                        miniatureProps={{position: "none"}}
                                        scaleFactor={1.2}
                                        scaleFactorOnWheel={1.2}
                                        scaleFactorMin={1.0}
                                        preventPanOutside={true}
                                        detectAutoPan={false}
                                        disableDoubleClickZoomWithToolAuto={true}
                                        onChangeValue={changeValue}>
                                        <svg width={width}
                                             height={height - 64}>
                                            {content}
                                        </svg>
                                    </ReactSVGPanZoom>
                                )}/>
                        )
                    }
                </AutoSizer>

            </Dialog>
        </>
    )
}

export default TooltipMap;